package react

import csstype.*
import emotion.react.css

fun PropsWithClassName.setNavBarElementStyle(selected: Boolean) {
    this.css {
        float = Float.left
        color = Color("#f2f2f2")
        textAlign = TextAlign.center
        padding = Padding(14.px, 16.px)
        fontSize = 17.px

        if (selected) {
            backgroundColor = Color("#BB0000")
            color = NamedColor.white
        } else {
            hover {
                backgroundColor = Color("#ddd")
                color = NamedColor.black
            }
        }
    }
}