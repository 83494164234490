package model

enum class Settlement {
    LSE,
    Smart7,
    Trinity,
    Nero,
    Storno;

    companion object {
        fun fromString(settlement: String): Settlement =
            Settlement.values().first { it.name == settlement }
    }
}