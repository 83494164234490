package react.overview

import csstype.*
import emotion.react.css
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import model.Scan
import model.Settlement
import org.w3c.dom.HTMLTableCellElement
import react.ChildrenBuilder
import react.dom.html.InputType
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.select
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.th
import react.dom.html.ReactHTML.tr
import react.dom.html.TdHTMLAttributes
import setSettlementOrNode
import kotlin.js.Date

fun ChildrenBuilder.showList(data: List<Scan>, scope: CoroutineScope, refreshData: () -> Unit, isEbay: Boolean) {
    table {
        css {
            marginTop = 8.px
            borderCollapse = BorderCollapse.collapse
            width = 100.pct
        }
        tr {
            th { +"Barcode" }
            th { +"Produkt" }
            if (!isEbay) {
                th { +"Nero" }
                th { +"Digikex" }
            }
            th { +"Versendet" }
            if (!isEbay) {
                th { +"Verrechnung" }
                th { +"Notiz" }
            }
        }
        data.forEachIndexed { index, scan ->
            tr {
                if (scan.settlement == Settlement.Storno) {
                    css {
                        backgroundColor = Color("#BB0000")
                    }
                } else if (index % 2 == 0) {
                    css {
                        backgroundColor = Color("#dddddd")
                    }
                }
                scanItem(
                    scan = scan,
                    scope = scope,
                    refreshData = refreshData,
                    isEbay = isEbay
                )
            }
        }
    }
}


fun ChildrenBuilder.scanItem(scan: Scan, scope: CoroutineScope, refreshData: () -> Unit, isEbay: Boolean) {
    td {
        setCssForTdTr()
        +scan.barcode
    }
    td {
        setCssForTdTr()
        +scan.product
    }
    if (!isEbay) {
        td {
            if (scan.productStatus.nero.isNullOrEmpty()) {
                setCssForTdTr(center = true)
                +"-"
            } else {
                setCssForTdTr()
                +Date(scan.productStatus.nero).toFormatedDate()
            }
        }
        td {
            if (scan.productStatus.digikex.isNullOrEmpty()) {
                setCssForTdTr(center = true)
                +"-"
            } else {
                setCssForTdTr()
                +Date(scan.productStatus.digikex).toFormatedDate()
            }
        }
    }
    td {
        if (scan.productStatus.versendet.isNullOrEmpty()) {
            setCssForTdTr(center = true)
            +"-"
        } else {
            setCssForTdTr()
            +Date(scan.productStatus.versendet).toFormatedDate()
        }
    }
    if (!isEbay) {
        td {
            setCssForTdTr(center = true)
            addSettlement(
                settlement = scan.settlement,
                onSettlementChanged = {
                    scope.launch {
                        if (
                            setSettlementOrNode(
                                barcode = scan.barcode,
                                settlement = it,
                                node = null
                            )
                        ) {
                            refreshData()
                        }
                    }
                }
            )
        }
        td {
            setCssForTdTr(center = true)
            var myValue = scan.node
            input {
                css {
                    width = if (myValue.length < 15) {
                        15.ch
                    } else {
                        myValue.length.ch
                    }
                }
                id = "node"
                type = InputType.text
                defaultValue = myValue
                placeholder = "Notiz"
                onChange = { event ->
                    val lastValue = event.target.value
                    myValue = event.target.value
                    scope.launch {
                        delay(1000)
                        if (lastValue == myValue) {
                            scope.launch {
                                if (
                                    setSettlementOrNode(
                                        barcode = scan.barcode,
                                        settlement = null,
                                        node = lastValue
                                    )
                                ) {
                                    refreshData()
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun TdHTMLAttributes<HTMLTableCellElement>.setCssForTdTr(center: Boolean = false) {
    css {
        border = Border(1.px, LineStyle.solid)
        textAlign = if (center) {
            TextAlign.center
        } else {
            TextAlign.left
        }
        padding = 8.px
    }
}

fun Date.toFormatedDate(): String {
    val builder = StringBuilder()
    builder.append(this.toLocaleDateString())
    builder.append(" - ")
    builder.append(this.toLocaleTimeString())
    return builder.toString()
}

fun ChildrenBuilder.addSettlement(settlement: Settlement, onSettlementChanged: (Settlement) -> Unit) {
    select {
        defaultValue = settlement.name
        onChange = {
            onSettlementChanged(Settlement.fromString(it.target.value))
        }
        Settlement.values().forEach {
            option {
                +it.name
            }
        }
    }
}