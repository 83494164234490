import kotlinx.browser.document
import org.w3c.dom.asList
import react.Login
import react.overview.Overview
import react.create
import react.dom.client.createRoot

fun main() {
    val container = document.createElement("div")
    document.body!!.appendChild(container)

    val element = document.body!!.children.asList().firstOrNull { element ->
        element.tagName == "META" && element.getAttribute("name") == "PAGE"
    }

    val page = when (
        Page.toPage(
            element?.getAttribute("content")
        )
    ) {
        Page.OVERVIEW -> Overview.create()
        Page.LOGIN -> Login.create()
        Page.NONE -> Login.create()
    }

    createRoot(container).render(page)
}