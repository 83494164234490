package react.overview

import csstype.*
import emotion.react.css
import getEbayScans
import getScanOrNull
import getScans
import kotlinext.js.js
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import model.Category
import model.ProductStatus
import model.Scan
import model.ShowSettings
import react.FC
import react.Props
import react.dom.html.InputType
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.select
import react.overview.accountstaff.AccountStaffItem
import react.overview.accountstaff.AccountStaffProps
import react.setNavBarElementStyle
import react.useState
import kotlin.js.Date

enum class NavBarElements {
    Alle,
    Nero,
    Digikex,
    Versendet,
    Suche,
    Ebay,
    Buchhaltung
}

private val scope = MainScope()

val Overview = FC<Props> {
    var barcode by useState("")

    var data by useState(emptyList<Scan>())
    var item by useState<Scan?>(null)
    var date by useState("${Date().getFullYear()}-${(Date().getMonth() + 1).format()}-${Date().getDate().format()}")
    var showSettings by useState(ShowSettings.Datum.name)
    var category by useState(Category.PC.name)

    var initialLoad by useState(true)

    var selectedMenuItem by useState(NavBarElements.Alle)

    if (initialLoad) {
        scope.launch {
            data = getScans(
                date = date,
                status = when (selectedMenuItem) {
                    NavBarElements.Nero -> ProductStatus.Status.NERO
                    NavBarElements.Digikex -> ProductStatus.Status.DIGIKEX
                    NavBarElements.Versendet -> ProductStatus.Status.VERSENDET
                    else -> null
                },
                showSettings = ShowSettings.fromString(showSettings),
                category = Category.fromString(category)
            )
        }
        initialLoad = false
    }

    div {
        css {
            overflow = Overflow.hidden
            backgroundColor = Color("#333")
        }
        NavBarElements.entries.forEach { navBarElement ->
            a {
                id = navBarElement.name
                setNavBarElementStyle(navBarElement == selectedMenuItem)
                onClick = {
                    if (navBarElement != selectedMenuItem) {
                        data = emptyList()
                    }
                    selectedMenuItem = navBarElement
                    scope.launch {
                        data = if (navBarElement == NavBarElements.Suche) {
                            emptyList()
                        } else {
                            if (navBarElement == NavBarElements.Ebay) {
                                getEbayScans(
                                    date = date
                                )
                            } else if (navBarElement != NavBarElements.Buchhaltung) {
                                getScans(
                                    date = date,
                                    status = when (navBarElement) {
                                        NavBarElements.Nero -> ProductStatus.Status.NERO
                                        NavBarElements.Digikex -> ProductStatus.Status.DIGIKEX
                                        NavBarElements.Versendet -> ProductStatus.Status.VERSENDET
                                        else -> null
                                    },
                                    showSettings = ShowSettings.fromString(showSettings),
                                    category = Category.fromString(category)
                                )
                            } else {
                                emptyList()
                            }
                        }
                    }
                }
                +navBarElement.name
            }
        }
    }

    when (selectedMenuItem) {
        NavBarElements.Suche -> {
            h3 {
                +"Barcode suchen"
            }
            input {
                css {
                    marginTop = 5.px
                    marginBottom = 5.px
                    fontSize = 14.px
                }
                type = InputType.text
                placeholder = "Barcode"
                value = barcode
                onChange = { event ->
                    barcode = event.target.value
                }
                onKeyDown = {
                    if (it.key == "Enter") {
                        scope.launch {
                            item = getScanOrNull(barcode)
                        }
                    }
                }
            }
            button {
                +"Suchen"
                onClick = {
                    scope.launch {
                        item = getScanOrNull(barcode)
                    }
                }
            }
            if (item != null) {
                showList(
                    data = listOf(item as Scan),
                    scope = scope,
                    refreshData = {
                        scope.launch {
                            data = getScans(
                                date = date,
                                status = when (selectedMenuItem) {
                                    NavBarElements.Nero -> ProductStatus.Status.NERO
                                    NavBarElements.Digikex -> ProductStatus.Status.DIGIKEX
                                    NavBarElements.Versendet -> ProductStatus.Status.VERSENDET
                                    else -> null
                                },
                                showSettings = ShowSettings.fromString(showSettings),
                                category = Category.fromString(category)
                            )
                        }
                    },
                    isEbay = selectedMenuItem == NavBarElements.Ebay
                )
            }
        }

        NavBarElements.Buchhaltung -> {
            @Suppress("UNCHECKED_CAST_TO_EXTERNAL_INTERFACE")
            child(
                type = AccountStaffItem,
                props = js {
                    this["scope"] = scope
                } as AccountStaffProps
            )
        }

        else -> {
            if (
                showSettings == ShowSettings.Datum.name ||
                selectedMenuItem == NavBarElements.Alle ||
                selectedMenuItem == NavBarElements.Versendet ||
                selectedMenuItem == NavBarElements.Ebay
            ) {
                input {
                    css {
                        margin = 2.px
                        padding = 4.px
                    }
                    type = InputType.date
                    value = date
                    onChange = {
                        date = it.target.value
                        data = emptyList()
                        scope.launch {
                            if (selectedMenuItem == NavBarElements.Ebay) {
                                data = getEbayScans(
                                    date = it.target.value
                                )
                            } else {
                                data = getScans(
                                    date = it.target.value,
                                    status = when (selectedMenuItem) {
                                        NavBarElements.Nero -> ProductStatus.Status.NERO
                                        NavBarElements.Digikex -> ProductStatus.Status.DIGIKEX
                                        NavBarElements.Versendet -> ProductStatus.Status.VERSENDET
                                        else -> null
                                    },
                                    showSettings = ShowSettings.fromString(showSettings),
                                    category = Category.fromString(category)
                                )
                            }
                        }
                    }
                }
                if (selectedMenuItem != NavBarElements.Ebay && selectedMenuItem != NavBarElements.Buchhaltung) {
                    select {
                        css {
                            padding = 4.px
                            margin = 6.px
                        }
                        value = category
                        onChange = {
                            category = it.target.value
                            scope.launch {
                                data = getScans(
                                    date = date,
                                    status = when (selectedMenuItem) {
                                        NavBarElements.Nero -> ProductStatus.Status.NERO
                                        NavBarElements.Digikex -> ProductStatus.Status.DIGIKEX
                                        NavBarElements.Versendet -> ProductStatus.Status.VERSENDET
                                        else -> null
                                    },
                                    showSettings = ShowSettings.fromString(showSettings),
                                    category = Category.fromString(it.target.value)
                                )
                            }
                        }
                        Category.entries.forEach {
                            option {
                                +it.name
                            }
                        }
                    }
                }
            }
            if (
                selectedMenuItem != NavBarElements.Alle
                && selectedMenuItem != NavBarElements.Versendet
                && selectedMenuItem != NavBarElements.Ebay
            ) {
                select {
                    css {
                        padding = 2.px
                        margin = 4.px
                    }
                    value = showSettings
                    onChange = {
                        showSettings = it.target.value
                        scope.launch {
                            data = getScans(
                                date = date,
                                status = when (selectedMenuItem) {
                                    NavBarElements.Nero -> ProductStatus.Status.NERO
                                    NavBarElements.Digikex -> ProductStatus.Status.DIGIKEX
                                    NavBarElements.Versendet -> ProductStatus.Status.VERSENDET
                                    else -> null
                                },
                                showSettings = ShowSettings.fromString(it.target.value),
                                category = Category.fromString(category)
                            )
                        }
                    }
                    ShowSettings.entries.forEach {
                        option {
                            +it.name
                        }
                    }
                }
            }
            showList(
                data = data,
                scope = scope,
                refreshData = {
                    scope.launch {
                        data = getScans(
                            date = date,
                            status = when (selectedMenuItem) {
                                NavBarElements.Nero -> ProductStatus.Status.NERO
                                NavBarElements.Digikex -> ProductStatus.Status.DIGIKEX
                                NavBarElements.Versendet -> ProductStatus.Status.VERSENDET
                                else -> null
                            },
                            showSettings = ShowSettings.fromString(showSettings),
                            category = Category.fromString(category)
                        )
                    }
                },
                isEbay = selectedMenuItem == NavBarElements.Ebay
            )
            div {
                css {
                    padding = 8.px
                    fontWeight = FontWeight.bold
                    float = Float.right
                }
                +"Stück: ${data.size}"
            }
        }
    }
}

fun Int.format(): String {
    return if (this < 10) {
        "0${this}"
    } else {
        this.toString()
    }
}