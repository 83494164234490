package model

import exception.EnumException
import kotlinx.serialization.Serializable

@Serializable
data class ProductStatus(
    val nero: String? = null,
    val digikex: String? = null,
    val versendet: String? = null
) {
    val status: Status = when {
        versendet.isNullOrEmpty().not() -> Status.VERSENDET
        digikex.isNullOrEmpty().not() -> Status.DIGIKEX
        nero.isNullOrEmpty().not() -> Status.NERO
        else -> {
            throw EnumException("All status dates are null")
        }
    }
    enum class Status {
        NERO,
        DIGIKEX,
        VERSENDET;

        companion object {
            fun fromString(status: String): Status {
                return when(status) {
                    NERO.name -> NERO
                    DIGIKEX.name -> DIGIKEX
                    VERSENDET.name -> VERSENDET
                    else -> {
                        throw EnumException("Product for string not found: $status")
                    }
                }
            }
        }
    }
}