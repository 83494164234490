package model

enum class ShowSettings {
    Datum,
    Status;

    companion object {
        fun fromString(showSettings: String): ShowSettings =
            when (showSettings) {
                Datum.name -> Datum
                Status.name -> Status
                else -> Datum
            }
    }
}