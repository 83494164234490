package model

import kotlinx.serialization.Serializable

@Serializable
data class AccountStaff(
    val product: String,
    val quantity: Int,
    val node: String?
) {
    companion object {
        const val ACCOUNT_STAFF_PATH_API = "/api/account-staff"
        const val ACCOUNT_STAFF_PATH_SERVER = "/account-staff"
    }
}