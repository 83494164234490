package react.overview.accountstaff

import csstype.*
import emotion.react.css
import getAccountStaff
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import model.AccountStaff
import model.Settlement
import react.ChildrenBuilder
import react.FC
import react.Props
import react.dom.html.InputType
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.select
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.th
import react.dom.html.ReactHTML.tr
import react.overview.format
import react.useState
import kotlin.js.Date


external interface AccountStaffProps : Props {
    var scope: CoroutineScope
}

val AccountStaffItem = FC<AccountStaffProps> { props ->
    val scope = props.scope

    var data by useState(emptyList<AccountStaff>())
    var selectedSettlement by useState(Settlement.LSE.name)
    var date by useState("${Date().getFullYear()}-${(Date().getMonth() + 1).format()}-${Date().getDate().format()}")
    var initialLoad by useState(true)

    if (initialLoad) {
        scope.launch {
            data = getAccountStaff(
                date = date,
                settlement = Settlement.fromString(selectedSettlement)
            )
        }
        initialLoad = false
    }

    input {
        css {
            margin = 2.px
            padding = 4.px
        }
        type = InputType.date
        value = date
        onChange = {
            date = it.target.value
            data = emptyList()
            scope.launch {
                data = getAccountStaff(
                    date = it.target.value,
                    settlement = Settlement.fromString(selectedSettlement)
                )
            }
        }
    }
    select {
        css {
            margin = 2.px
            padding = 4.px
        }
        value = selectedSettlement
        onChange = {
            selectedSettlement = it.target.value
            scope.launch {
                data = getAccountStaff(
                    date = date,
                    settlement = Settlement.fromString(it.target.value)
                )
            }
        }
        Settlement.entries.filter { it != Settlement.Storno }.forEach {
            option {
                +it.name
            }
        }
    }

    table {
        css {
            marginTop = 8.px
            borderCollapse = BorderCollapse.collapse
            width = 100.pct
        }
        tr {
            th { +"Produkt" }
            th { +"Stückzahl" }
            th { +"Notiz" }
        }
        showListAccountStaffList(data)
    }
}

private fun ChildrenBuilder.showListAccountStaffList(data: List<AccountStaff>) {
    data.forEachIndexed { index, accountStaff ->
        tr {
            if (index % 2 == 0) {
                css {
                    backgroundColor = Color("#dddddd")
                }
            }
            td {
                css {
                    textAlign = TextAlign.center
                }
                +accountStaff.product
            }
            td {
                css {
                    textAlign = TextAlign.center
                }
                +accountStaff.quantity.toString()
            }
            td {
                css {
                    textAlign = TextAlign.center
                }
                +accountStaff.node.orEmpty()
            }
        }
    }
}