enum class Page {
    OVERVIEW,
    LOGIN,
    NONE;

    companion object {
        fun toPage(page: String?): Page =
            when (page) {
                OVERVIEW.name -> OVERVIEW
                LOGIN.name -> LOGIN
                else -> NONE
            }
    }
}