import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.engine.js.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.logging.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import model.*
import model.AccountStaff.Companion.ACCOUNT_STAFF_PATH_API

val jsonClient = HttpClient(Js) {
    followRedirects = false
    install(ContentNegotiation) {
        json()
    }
    install(Logging)
}

suspend fun login(user: UserLogin): String? {
    val response = jsonClient.post("/login") {
        contentType(ContentType.Application.Json)
        setBody(user)
    }
    return if (response.status == HttpStatusCode.Forbidden) {
        null
    } else {
        response.body()
    }
}

suspend fun getScanOrNull(barcode: String): Scan? =
    jsonClient.get("${Scan.PATH_API}/$barcode").body()

suspend fun getScans(date: String?, status: ProductStatus.Status?, showSettings: ShowSettings, category: Category): List<Scan> {
    return jsonClient.get(Scan.PATH_API) {
        url {
            if (!date.isNullOrEmpty()) {
                parameters.append(QUERY_PARAMTER_DATE, date)
            }
            if (status != null) {
                parameters.append(QUERY_PARAMTER_STATUS, status.name)
            }
            parameters.append(QUERY_PARAMTER_SHOW_SETTINGS, showSettings.name)
            parameters.append(QUERY_PARAMTER_CATEGORY, category.name)
        }
    }.body()
}

suspend fun getEbayScans(date: String): List<Scan> {
    val response = jsonClient.get(Scan.EBAY_PATH_API) {
        url {
            parameters.append(QUERY_PARAMTER_DATE, date)
        }
    }.body<List<Scan>>()
    return response
}

suspend fun setSettlementOrNode(barcode: String, settlement: Settlement?, node: String?): Boolean {
    return jsonClient.post(Scan.PATH_API_UPDATE) {
        url {
            parameters.append(QUERY_PARAMTER_BARCODE, barcode)
            if (settlement != null) {
                parameters.append(QUERY_PARAMTER_SETTLEMENT, settlement.name)
            }
            if (node != null) {
                parameters.append(QUERY_PARAMTER_NODE, node)
            }
        }
    }.body()
}

suspend fun getAccountStaff(date: String?, settlement: Settlement): List<AccountStaff> {
    return jsonClient.get(ACCOUNT_STAFF_PATH_API) {
        url {
            if (!date.isNullOrEmpty()) {
                parameters.append(QUERY_PARAMTER_DATE, date)
            }
            parameters.append(QUERY_PARAMTER_SETTLEMENT, settlement.name)
        }
    }.body()
}