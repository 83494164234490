package model

import kotlinx.serialization.Serializable

@Serializable
data class Scan(
    val barcode: String,
    val product: String,
    val productStatus: ProductStatus,
    val settlement: Settlement = Settlement.LSE,
    val node: String = "",
    val category: Category
) {
    companion object {
        const val PATH_API = "/api/scans"
        const val PATH_SERVER = "/scans"

        const val PATH_API_UPDATE = "/api/scans/update"
        const val PATH_SERVER_UPDATE = "/scans/update"

        const val EBAY_PATH_API = "/api/ebay/scans"
        const val EBAY_PATH_SERVER = "/ebay/scans"

        const val REFRESH_PRODUCTS = "/refresh-products"
        const val GET_PRODUCTS = "/products"
    }
}
